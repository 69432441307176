import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptopCode,
  faLightbulb,
  faHandshake,
  faComments,
} from '@fortawesome/free-solid-svg-icons';

const whyHireData = [
  {
    id: 1,
    title: 'Expertise in Modern Technologies',
    description: 'Extensive experience with React, Foundation 6, and other cutting-edge technologies.',
    icon: faLaptopCode,
  },
  {
    id: 2,
    title: 'Creative Problem-Solving',
    description: 'Innovative solutions tailored to meet project requirements and overcome challenges.',
    icon: faLightbulb,
  },
  {
    id: 3,
    title: 'Strong Work Ethic',
    description: 'Committed to delivering high-quality work on time and exceeding client expectations.',
    icon: faHandshake,
  },
  {
    id: 4,
    title: 'Excellent Communication',
    description: 'Clear and effective communication to ensure smooth project collaboration.',
    icon: faComments,
  },
];

const WhyHireMe = () => {
  return (
    <div className="skills-section why-hire-section" data-aos="fade-up">
      <div className="skills-grid grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2">
        {whyHireData.map((item) => (
          <div className="skill-card cell" key={item.id}>
            <div className="skills__content">
              <FontAwesomeIcon className="skills__content__icon" icon={item.icon} />
              <p className="skills__content__title">{item.title}</p>
              <p className="skills__content__description">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyHireMe;
