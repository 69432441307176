const skillsData = {
  codingSkills: [
    {
      id: 1,
      skill: 'Front-End Wizardry',
      level: 95,
      icon: 'fas fa-laptop-code',
      description: 'Turning lifeless designs into interactive wonders with HTML5, CSS3/SASS, and JavaScript. Basically, if it’s broken, I can probably fix it (or blame caching).',
    },
    {
      id: 2,
      skill: 'JavaScript Jedi Apprentice',
      level: 75,
      icon: 'fab fa-js-square',
      description: 'Harnessing the power of ReactJS, Next.js, and jQuery to create web experiences that feel like magic. Still mastering the force, but console.log is only for Sith Lords now.',
    },    
    {
      id: 3,
      skill: 'Back-End Sorcery',
      level: 70,
      icon: 'fas fa-server',
      description: 'PHP and Node.js. Conjuring APIs and keeping the server spirits happy (most of the time).',
    },
    {
      id: 4,
      skill: 'CMS Whisperer',
      level: 90,
      icon: 'fas fa-cms',
      description: 'WordPress and Shopify master. Fluent in Liquid templating and headless CMS sorcery. My WordPress dashboards could probably host the Olympics.',
    },
    {
      id: 5,
      skill: 'Database Juggler',
      level: 70,
      icon: 'fas fa-database',
      description: 'MySQL and Firebase. Data is stored safely… most of the time (except for that one time, but we don’t talk about that).',
    },
  ],
  designSkills: [
    {
      id: 6,
      skill: 'UI/UX Visionary',
      level: 80,
      icon: 'fas fa-paint-brush',
      description: 'Crafting experiences so smooth, users might forget they’re on a website. Addicted to pixel perfection and good vibes.',
    },
    {
      id: 7,
      skill: 'Digital Picasso',
      level: 75,
      icon: 'fas fa-pencil-alt',
      description: 'From Photoshop masterpieces to Illustrator magic, I design things that make people go “Wow, who made this?” Spoiler: it was me.',
    },
    {
      id: 8,
      skill: 'Branding Guru (in training)',
      level: 50,
      icon: 'fas fa-id-badge',
      description: 'Branding identities that scream “Hire me!” or “Buy this!”—but let’s face it, sometimes they whisper too.',
    },
  ],
  additionalSkills: [
    {
      id: 9,
      skill: 'Speed Demon & SEO Ninja',
      level: 85,
      icon: 'fas fa-chart-line',
      description: 'Making websites load faster than your morning coffee and optimizing them so Google loves you more than your dog does.',
    },
    {
      id: 10,
      skill: 'Git God (Kinda)',
      level: 75,
      icon: 'fas fa-code-branch',
      description: 'GitHub, Bitbucket, GitLab—you name it. Just don’t ask about merge conflicts on a Monday morning.',
    },
    {
      id: 11,
      skill: 'Professional Overthinker',
      level: 100,
      icon: 'fas fa-brain',
      description: 'Meticulously planning every detail of a project before realizing deadlines exist. My overthinking is your gain.',
    },
    {
      id: 12,
      skill: 'Coffee Enthusiast',
      level: 100,
      icon: 'fas fa-mug-hot',
      description: 'Fueled by caffeine and ambition. Capable of solving any bug as long as there’s coffee within arm’s reach.',
    },
    {
      id: 13,
      skill: 'Trailblazing Adventurer',
      level: 100,
      icon: 'fas fa-hiking',
      description: 'Master of both debugging and hiking trails. Whether it’s conquering mountains or fixing code, I’m always up for the challenge (preferably with good shoes and better snacks).',
    },
  ]    
};

export default skillsData;
