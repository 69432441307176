import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';

const Footer = () => (
  <footer className="site-footer">
    <div className="grid-container">
      <div className="grid-x grid-margin-x align-center">
        <div className="cell">
          <ul className="social-media-list">
            <li>
              <a href="https://www.linkedin.com/in/corey-rotstein/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faLinkedin} size="2x" />
              </a>
            </li>
            <li>
              <a href="https://firebasestorage.googleapis.com/v0/b/my-portfolio-1fc61.appspot.com/o/CoreyRotstein-Resume.pdf?alt=media&token=a5ce1ac1-8308-49ea-bb0a-8a2f732b2c6f" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faFileDownload} size="2x" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
