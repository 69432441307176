import React from 'react';
import skillsData from '../data/skillsData';

const SkillsSection = () => {
  const { designSkills, codingSkills, additionalSkills } = skillsData;

  const renderSkillCard = (skill) => (
    <div className="skill-card cell" key={skill.id}>
      <div className="skills__content">
        <i className={`skills__content__icon ${skill.icon}`}></i>
        <p className="skills__content__title">{skill.skill}</p>
        <p className="skills__content__description">{skill.description}</p>
        <div className="skill-bar-container">
          <div className="skill-bar">
            <div
              className="skill-bar-fill"
              style={{ width: `${skill.level}%` }}
              aria-label={`${skill.skill} proficiency level: ${skill.level}%`}
            >
              <span className="skill-bar-label">{skill.level}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="skills-section" data-aos="fade-up">
      <div className="skills-category">
        <h3 className="skills-category__heading">Coding Skills</h3>
        <div className="skills-grid grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-3">
          {codingSkills.map((skill) => renderSkillCard(skill))}
        </div>
      </div>
      <hr className="skills-divider" />
      <div className="skills-category">
        <h3 className="skills-category__heading">Design Skills</h3>
        <div className="skills-grid grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-3">
          {designSkills.map((skill) => renderSkillCard(skill))}
        </div>
      </div>
      <hr className="skills-divider" />
      <div className="skills-category">
        <h3 className="skills-category__heading">Additional Skills</h3>
        <div className="skills-grid grid-x grid-margin-x grid-margin-y small-up-1 medium-up-2 large-up-3">
          {additionalSkills.map((skill) => renderSkillCard(skill))}
        </div>
      </div>
    </div>
  );
};

export default SkillsSection;
